//---------------------------------------------
//
//  Hamburger
//
//---------------------------------------------

$hamburger-padding-x           : 15px;
$hamburger-padding-y           : 15px;
$hamburger-layer-width         : 40px;
$hamburger-layer-height        : 4px;
$hamburger-layer-spacing       : 6px;
$hamburger-layer-color         : #000;
$hamburger-layer-border-radius : 4px;
$hamburger-hover-opacity       : 0.7;
$hamburger-active-layer-color  : $hamburger-layer-color;
$hamburger-active-hover-opacity: $hamburger-hover-opacity;
 
$hamburger-hover-use-filter   : false;
$hamburger-hover-filter       : opacity(50%);
$hamburger-active-hover-filter: $hamburger-hover-filter;
 
$hamburger-types: (
  elastic,
);

@import "~hamburgers/_sass/hamburgers/hamburgers";
