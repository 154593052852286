//---------------------------------------------
//
//  Variables
//
//    1. Bootstrap Fonts
//    2. Bootstrap Globals
//    3. Bootstrap Colors
//    4. Bootstrap Container Width
//    5. Bootstrap Offcanvas
//    6. Bootstrap Carousel
//
//---------------------------------------------

//---------------------------------------------
// 1. Bootstrap Fonts
//---------------------------------------------

//$font-family-base: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
//$font-family-base: "YuMincho", "Hiragino Mincho ProN W3", "Hiragino Mincho ProN", serif; 
$font-family-base: "Helvetica Neue", "Helvetica", "Hiragino Sans", "Hiragino Kaku Gothic ProN", "Arial", "Yu Gothic", "Meiryo", sans-serif;

//---------------------------------------------
// 2. Bootstrap Globals
//---------------------------------------------

$enable-rounded: true;
$enable-shadows: false;
$enable-gradients: false;

//---------------------------------------------
// 3. Bootstrap Colors
//---------------------------------------------

$body-color: #000000; // The default text color

$primary:    #c93517;
$secondary:  #6c757d;
$success:    #198754;
$info:       #0dcaf0;
$warning:    #ffc107;
$danger:     #dc3545;
$light:      #f8f9fa;
$dark:       #212529;

//---------------------------------------------
// 4. Bootstrap Container Width
//---------------------------------------------

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px
);

//---------------------------------------------
//    5. Bootstrap Offcanvas
//---------------------------------------------

$offcanvas-horizontal-width:        320px;
$offcanvas-vertical-height:         30vh;
$offcanvas-transition-duration:     .3s;

//---------------------------------------------
//    6. Bootstrap Carousel
//---------------------------------------------

// Carousel

// scss-docs-start carousel-variables
$carousel-control-color:             $dark !default;
$carousel-control-width:             15% !default;
$carousel-control-opacity:           .5 !default;
$carousel-control-hover-opacity:     .9 !default;
$carousel-control-transition:        opacity .15s ease !default;

$carousel-indicator-width:           30px !default;
$carousel-indicator-height:          3px !default;
$carousel-indicator-hit-area-height: 10px !default;
$carousel-indicator-spacer:          3px !default;
$carousel-indicator-opacity:         .5 !default;
$carousel-indicator-active-bg:       $light !default;
$carousel-indicator-active-opacity:  1 !default;
$carousel-indicator-transition:      opacity .6s ease !default;

$carousel-caption-width:             70% !default;
$carousel-caption-color:             $light !default;
$carousel-caption-padding-y:         1.25rem !default;
$carousel-caption-spacer:            1.25rem !default;

$carousel-control-icon-width:        2rem !default;

$carousel-control-prev-icon-bg:      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$carousel-control-color}'><path d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/></svg>") !default;
$carousel-control-next-icon-bg:      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$carousel-control-color}'><path d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/></svg>") !default;

$carousel-transition-duration:       .6s !default;
$carousel-transition:                transform $carousel-transition-duration ease-in-out !default; // Define transform transition first if using multiple transitions (e.g., `transform 2s ease, opacity .5s ease-out`)

$carousel-dark-indicator-active-bg:  $dark !default;
$carousel-dark-caption-color:        $dark !default;
$carousel-dark-control-icon-filter:  invert(1) grayscale(100) !default;
// scss-docs-end carousel-variables
$carousel-dark-control-icon-filter:  invert(1) grayscale(100);