//---------------------------------------------
//
//  Table of Contents
//
//    1. Basics
//    2. Page Layout
//    3. Components
//
//---------------------------------------------

//---------------------------------------------
// 1. Basics
//---------------------------------------------

@import "variables";
@import "bootstrap";
@import "fonts";
@import "hamburgers";
@import "global";
@import "typography";

//---------------------------------------------
// 2. Page Layout
//---------------------------------------------

body {
    position: relative;
}
/*
#hamburger{
    position: fixed;
    z-index: 5000;
    right: 0px;
    left: auto;
    top: 0;
}
*/
/* WordPress Basic */
iframe.wp-embedded-content {width:100%}

.page-numbers{
	@extend .page-link;
}
.page-numbers.current {
	background-color: $primary;
	color: $white;
}
.breadcrumbs a{
    color: $light;
    text-decoration: none;
}
.hero {
    height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    position: relative;
    overflow: hidden;
    
    video {
        height: 100%; 
        width: 177.77777778vh; 
        min-width: 100%; 
        min-height: 56.25vw;
        position: absolute; 
        top: 50%; 
        left: 50%; 
        transform: translate(-50%, -50%); 
    }
    div {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        z-index: 100;

        h1 {
            @extend .text-center;
            img{
                @extend .w-100;
                max-width: 100px;
            }
        }
        .description {
            @extend .text-center;
        }
    }
    &.xs{
        height: 18vh;
    }
    &.sm{
        height: 20vh;
    }
    &.md{
        height: 40vh;
    }
    &.overlay::after {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        content: "";
        background: rgba($black, 0.5);
    }

}

//---------------------------------------------
// 3.Components
//---------------------------------------------

@import "components/slider";