.typography {

    p{
      font-size: 1.0rem;
      line-height: 2.0rem;
    }
    strong{
      color: $primary;
      font-weight: bolder; 
    }
    blockquote {
        background-color: $gray-100;
        padding: 30px;
        margin: 15px 0;
        border-left: 3px $gray-400 solid;
    }
    figure.wp-block-image img,
    img.size-full
    {
      @extend .img-fluid;
      @extend .rounded;
      @extend .w-100;
    }
    .huge{
      font-size: 1.7rem;
      font-weight: bold;
      @extend .py-3;
  
      &.red{
        color: $red;
      }
      &.center{
        @extend .text-center;
      }
    }
    span.image.fit{
      display: none;
    }
    .wp-block-image.size-large a{
      position: relative;
      height: 100%;
    }
    .wp-block-image.size-large a:after{
      content: '\03e';
      font-size: 2rem;
      line-height: 2rem;
      position: absolute;
      top: -0.2rem;
      right: 10px;
      color: $white;
    }
    .wp-block-image{
      img {
        height: auto;
      }
    }
    figure{
      .wp-block-embed__wrapper{
        @extend .ratio;
        @extend .ratio-16x9;
      }
      figcaption{
        text-align: center;
        font-size: 80%;
      }
    }
    figure.wp-block-table table
    {
        @extend .table;
        @extend .table-striped;
        th{
            width: 28%;
        }
    }
  
  }
  .page {
  
    h2{
      font-size: 1.3em;
      padding: 15px 0 15px 20px;
      border-left: $primary 4px solid;
      border-bottom: $gray-400 1px dashed;
      margin: 25px 0;
    }
    h3{
      font-size: 1.1em;
      padding: 12px 0 12px 5px;
      border-bottom: $gray-400 1px dashed;
      line-height: 2.0rem;
    }
    h4{
      font-size: 1.0em;
      padding: 12px 0 12px 5px;
      margin: 15px 0;
      line-height: 1.5rem;
    }
  
  }